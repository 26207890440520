<template>
<b-container>
	<b-row>
		<b-col class="text-center">
			<img src="../../assets/logo.png" alt="Ki-sono" class="h-150px my-9">
		</b-col>
	</b-row>
	<b-row>
		<b-col>
			<h1 class="text-center mb-5 mt-5">Ki-sono</h1>
		</b-col>
	</b-row>
	<b-form @submit.prevent="onSubmit">
		<b-form-row>
			<b-col>
				<b-form-group
					label="Username"
					label-size="lg"
					label-for="input-username">
					<b-form-input
						id="input-username"
						required
						type="text"
						v-model="jsonData.username"></b-form-input>
				</b-form-group>
			</b-col>
		</b-form-row>
		<b-form-row>
			<b-col>
				<b-form-group
					label="Password"
					label-size="lg"
					label-for="input-password">
					<b-form-input
						id="input-password"
						required
						type="password"
						v-model="jsonData.password"></b-form-input>
				</b-form-group>
			</b-col>
		</b-form-row>
		<b-form-row class="mt-5">
			<b-col cols="12" class="text-center">
				<b-button
					type="submit"
					variant="primary"
					class="btn-lg">Accedi</b-button>
			</b-col>
		</b-form-row>
	</b-form>
</b-container>
</template>

<script>
import LoginServices from './services/LoginServices';

export default {
	name: "Login",
	data() {
		return {
			jsonData: {
				username: '',
				password: ''
			}
		}
	},
	methods: {
		onSubmit() {
			const {username, password} = this.jsonData;
			LoginServices.accedi(username, password)
				.then(response => {
					if (response.error)
						this.$showMsgBoxWarning('Accesso non riuscito.', response.msg, true);
					else {
						sessionStorage.setItem('accessToken', response.data.token);
						this.$router.push({name: 'DashboardHome'});
					}
				})
				.catch(error => {
					console.log(error);
				})
		}
	}
}
</script>

<style scoped>

</style>
