import axios from 'axios';

function accedi(username, password) {
	const base64 = btoa(`${username}:${password}`);
	return new Promise((resolve, reject) => {
		axios.post('api/dashboard/token', undefined, {
			headers: {
				'Content-Type': 'application/json',
				'Accept-Version': '1.0.0',
				authorization: `Basic ${base64}`
			}
		})
			.then(value => {
				resolve(value.data);
			})
			.catch(reason => {
				reject(reason);
			});
	});
}

export default {
	accedi
};
